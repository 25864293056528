export const URL_DASHBOARD = "/dashboard";
export const URL_LOGIN = "/login";
export const URL_PAGES = "/pages";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_CHANGE_PASSWORD = "/change-password";
export const URL_HASHTAG = "/hashtag";
export const URL_USER = "/user";
export const URL_ANALYTICS = "/analytics";
export const URL_POST = "/post";
export const URL_CATEGORY = "/category";
export const URL_COMMENT = "/post/view-comment/:id";
export const URL_COMMENT_REPORT = "/post/view-comment-report/:id";
export const URL_POST_REPORT = "/post/view-post-report/:id";
export const URL_USER_REPORT = "/user/view-user-report/:id";
export const URL_STATIC_PAGE = "/static-page";
export const URL_REQUESTS = "/requests";
export const URL_FEEDBACK = "/feedback";
export const URL_TEMPLATE = "/template";
export const URL_VERIFICATION = "/verification";
export const URL_DISCOVER = "/discover";
export const URL_EXPIRY_DURATION = "/moments-expiry";
export const URL_MOMENT = "/moments";
export const URL_POST_EXPIRY_DURATION = "/post-expiry";
export const URL_INTEREST = "/interest";
export const URL_SUB_INTEREST = "/sub-interest";
export const URL_RECOMENDED_INTEREST = "/recommended-interest";
export const URL_SHOT_TEMPLATE = "/shot-template";
export const URL_POST_SUGGESTION = "/post-suggestion";
export const URL_PUBLIC = "/public";
export const URL_GROUP = "/group";
export const URL_SUGGESTION = "/suggestion";
export const URL_SCRIPT = "/script";
export const URL_SCRIPT_CONTENT = "/script/content/:id";
export const URL_PUBLIC_VIEW = "/group/view-public/:id";
export const URL_PUBLIC_COMMENT = "/public/view-comment/:id";
export const URL_PUBLIC_REACTION = "/public/view-reaction/:id";
export const URL_PUBLIC_COMMENT_REPORT = "/public/view-comment-report/:id";
export const URL_PUBLIC_REACTION_REPORT = "/public/view-reaction-report/:id";
export const URL_COMMUNITY = "/community";
export const URL_COMMUNITY_PUBLIC_VIEW = "/community/view-public/:id";
