import React, { useState } from "react";
import {
    Input,
    Form,
    Button,
    message
} from "antd";
import Api from "Helpers/ApiHandler";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { URL_LOGIN } from "Helpers/Paths";
// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./ForgotPassword.style";
import LogoImage from "Assets/Images/logo.png";


export default function ForgotPassword(props) {
    let [loading, setLoading] = useState(false),
        [isOtpGet, setIsOtpGet] = useState(false),
        [isVerifiedEmail, setIsVerifiedEmail] = useState(false);

    function login() {
        props.history.push(URL_LOGIN);
    }
    async function formSubmit(values) {
        let dataValues = { ...values };

        try {
            setLoading(true);
            let config = {
                data: dataValues,
                returnUnhandledError: true,
                returnError: true,
            }
            let response;

            if (isVerifiedEmail) {
                response = await new Api().put("/auth/admin/reset-password", config);
                props.history.push(URL_LOGIN);
            }
            else if (isOtpGet) {
                response = await new Api().post("/auth/admin/verification", config);
                setIsVerifiedEmail(true);
            }
            else {
                response = await new Api().post("/auth/admin/forgot-password", config);
                setIsOtpGet(true);
            }
            message.success(response.message);
            // form.resetFields();

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(
                "TCL ~ file: Drawer.jsx ~ line 25 ~ formSubmit ~ error",
                error
            );
        }
    }

    return (
        <LoginWrapper className="flex f-v-center f-h-center">
            <LoginBox className="box">
                <LogoWrapper>
                    <img className="img" src={LogoImage} alt="Logo" />
                </LogoWrapper>
                {/* <PageTitle>Forgot Password</PageTitle> */}
                <Form
                    className="form-container"
                    onFinish={formSubmit}
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, type: 'email' }]}
                    >
                        <Input size="large" placeholder="Email" />
                    </Form.Item>
                    {isVerifiedEmail &&
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Please enter your new password!' },
                                { min: 6, message: 'Password should be atleast 6 chars long' },
                            ]}
                        >
                            <Input.Password
                                placeholder="New Password"
                                size="large"
                                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            />
                        </Form.Item>
                    }
                    {isOtpGet &&
                        <Form.Item
                            name="otp"
                            rules={[{ required: true }]}
                        >
                            <Input size="large" placeholder="OTP" />
                        </Form.Item>
                    }
                    <Form.Item>
                        <div className="redirection-link" onClick={login}>
                            {"login?"}
                        </div>

                        {!isOtpGet ?
                            <div className="button-container">
                                <Button
                                    className="submitButton"
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Send OTP
                                </Button>
                            </div>

                            :
                            <div className="button-container">
                                <Button
                                    className="submitButton"
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </div>
                        }
                    </Form.Item>
                </Form>
            </LoginBox>
        </LoginWrapper>

    );
}
