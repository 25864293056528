import React from "react";
import LazyLoader from "@loadable/component";

import {
  URL_DASHBOARD,
  URL_HASHTAG,
  URL_USER,
  URL_ANALYTICS,
  URL_POST,
  URL_CATEGORY,
  URL_COMMENT,
  URL_COMMENT_REPORT,
  URL_POST_REPORT,
  URL_USER_REPORT,
  URL_STATIC_PAGE,
  URL_FEEDBACK,
  URL_TEMPLATE,
  URL_VERIFICATION,
  URL_DISCOVER,
  URL_EXPIRY_DURATION,
  URL_MOMENT,
  URL_POST_EXPIRY_DURATION,
  URL_INTEREST,
  URL_SUB_INTEREST,
  URL_RECOMENDED_INTEREST,
  URL_SHOT_TEMPLATE,
  URL_POST_SUGGESTION,
  URL_PUBLIC,
  URL_GROUP,
  URL_SUGGESTION,
  URL_SCRIPT,
  URL_SCRIPT_CONTENT,
  URL_PUBLIC_VIEW,
  URL_PUBLIC_COMMENT,
  URL_PUBLIC_REACTION,
  URL_PUBLIC_COMMENT_REPORT,
  URL_PUBLIC_REACTION_REPORT,
  // URL_COMMUNITY,
  URL_COMMUNITY_PUBLIC_VIEW,
  URL_REQUESTS,
} from "Helpers/Paths";

import {
  PicLeftOutlined,
  BarChartOutlined,
  CopyrightOutlined,
  TagOutlined,
  FormOutlined,
  GatewayOutlined,
  StrikethroughOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
  MediumOutlined,
  DribbbleOutlined,
  InfoOutlined,
  UsergroupAddOutlined,
  LineChartOutlined,
} from "@ant-design/icons";

export default [
  {
    path: "/",
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
  },
  {
    path: URL_DASHBOARD,
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
    sidebar: {
      name: "Dashboard",
      icon: <BarChartOutlined />,
    },
  },
  {
    path: URL_USER,
    exact: true,
    component: LazyLoader(() => import("Components/User/User")),
    sidebar: {
      name: "User",
      icon: <UserOutlined />,
    },
  },
  {
    path: URL_ANALYTICS,
    exact: true,
    component: LazyLoader(() => import("Components/Analytics/Analytics")),
    sidebar: {
      name: "Analytics",
      icon: <LineChartOutlined />,
    },
  },
  {
    path: URL_VERIFICATION,
    exact: true,
    component: LazyLoader(() => import("Components/Verification/Verification")),
    sidebar: {
      name: "Verification",
      icon: <SafetyCertificateOutlined />,
    },
  },
  {
    path: URL_GROUP,
    exact: true,
    component: LazyLoader(() => import("Components/Group/Group")),
    sidebar: {
      name: "Collt",
      icon: <UsergroupAddOutlined />,
      nested: [
        {
          name: "Public",
          path: URL_PUBLIC,
          exact: true,
          component: LazyLoader(() => import("Components/Public/Public")),
        },
        // {
        //   name: "Community",
        //   path: URL_COMMUNITY,
        //   exact: true,
        //   component: LazyLoader(() => import("Components/Community/Community")),
        // },
        {
          name: "Group",
          path: URL_GROUP,
          exact: true,
          component: LazyLoader(() => import("Components/Group/Group")),
        },
        {
          name: "Suggestion",
          path: URL_SUGGESTION,
          exact: true,
          component: LazyLoader(() =>
            import("Components/Suggestion/Suggestion")
          ),
        },
        {
          name: "Script",
          path: URL_SCRIPT,
          exact: true,
          component: LazyLoader(() => import("Components/Script/Script")),
        },
      ],
    },
  },
  // {
  // 	path: URL_POST,
  // 	exact: true,
  // 	component: LazyLoader(() =>
  // 		import("Components/Post/Post")
  // 	),
  // 	sidebar: {
  // 		name: "Post",
  // 		icon: <PicLeftOutlined />,
  // 		nested: [
  // 			{
  // 				name: "Post",
  // 				path: URL_POST,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/Post/Post")),
  // 			},
  // 			{
  // 				name: "Post Expiry",
  // 				path: URL_POST_EXPIRY_DURATION,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/PostExpiry/ExpiryDuration")),
  // 			},
  // 			{
  // 				name: "Shot Template",
  // 				path: URL_SHOT_TEMPLATE,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/ShotTemplate/ShotTemplate.jsx")),
  // 			},
  // 			{
  // 				name: "Suggestion",
  // 				path: URL_POST_SUGGESTION,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/PostSuggestion/PostSuggestion")),
  // 			},
  // 		],
  // 	},
  // },
  // {
  // 	path: URL_EXPIRY_DURATION,
  // 	exact: true,
  // 	component: LazyLoader(() =>
  // 		import("Components/Moments/ExpiryDuration")
  // 	),
  // 	sidebar: {
  // 		name: "Moments",
  // 		icon: <MediumOutlined />,
  // 		nested: [
  // 			{
  // 				name: "Moments",
  // 				path: URL_MOMENT,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/Moment/Moment")),
  // 			},
  // 			{
  // 				name: "Expiry",
  // 				path: URL_EXPIRY_DURATION,
  // 				exact: true,
  // 				component: LazyLoader(() => import("Components/Moments/ExpiryDuration")),
  // 			},
  // 		],
  // 	},
  // },
  {
    path: URL_PUBLIC_VIEW,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Public")),
  },
  {
    path: URL_COMMUNITY_PUBLIC_VIEW,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Public")),
  },
  {
    name: "Script",
    path: URL_SCRIPT_CONTENT,
    exact: true,
    component: LazyLoader(() => import("Components/Content/Content")),
  },
  {
    path: URL_COMMENT,
    exact: true,
    component: LazyLoader(() => import("Components/Comments/Comments")),
  },
  {
    path: URL_PUBLIC_COMMENT,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Comments")),
  },
  {
    path: URL_PUBLIC_REACTION,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Reactions")),
  },
  {
    path: URL_PUBLIC_COMMENT_REPORT,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Report")),
  },
  {
    path: URL_PUBLIC_REACTION_REPORT,
    exact: true,
    component: LazyLoader(() => import("Components/Public/Report")),
  },
  {
    path: URL_POST_REPORT,
    exact: true,
    component: LazyLoader(() => import("Components/Report/Report")),
  },
  {
    path: URL_COMMENT_REPORT,
    exact: true,
    component: LazyLoader(() => import("Components/Report/Report")),
  },
  {
    path: URL_USER_REPORT,
    exact: true,
    component: LazyLoader(() => import("Components/Report/Report")),
  },
  {
    path: URL_CATEGORY,
    exact: true,
    component: LazyLoader(() => import("Components/Category/Category")),
    sidebar: {
      name: "Category",
      icon: <CopyrightOutlined />,
    },
  },
  {
    path: URL_INTEREST,
    exact: true,
    component: LazyLoader(() => import("Components/Interest/Interest")),
    sidebar: {
      name: "Interest",
      icon: <InfoOutlined />,
      nested: [
        {
          name: "Interest",
          path: URL_INTEREST,
          exact: true,
          component: LazyLoader(() => import("Components/Interest/Interest")),
        },
        {
          name: "Recommended",
          path: URL_RECOMENDED_INTEREST,
          exact: true,
          component: LazyLoader(() =>
            import("Components/RecomendedInterest/RecomendedInterest")
          ),
        },
      ],
    },
  },
  {
    path: URL_SUB_INTEREST,
    exact: true,
    component: LazyLoader(() => import("Components/SubInterest/SubInterest")),
  },
  {
    path: URL_DISCOVER,
    exact: true,
    component: LazyLoader(() => import("Components/Discover/Discover")),
    sidebar: {
      name: "Discover",
      icon: <DribbbleOutlined />,
    },
  },
  {
    path: URL_HASHTAG,
    exact: true,
    component: LazyLoader(() => import("Components/Hashtag/Hashtag")),
    sidebar: {
      name: "Hashtag",
      icon: <TagOutlined />,
    },
  },
  {
    path: URL_REQUESTS,
    exact: true,
    component: LazyLoader(() => import("Components/Requests/Requests")),
    sidebar: {
      name: "Requests",
      icon: <FormOutlined />,
    },
  },
  {
    path: URL_FEEDBACK,
    exact: true,
    component: LazyLoader(() => import("Components/Feedback/Feedback")),
    sidebar: {
      name: "Feedback",
      icon: <FormOutlined />,
    },
  },
  {
    path: URL_TEMPLATE,
    exact: true,
    component: LazyLoader(() => import("Components/Template/Template")),
    sidebar: {
      name: "Template",
      icon: <GatewayOutlined />,
    },
  },
  {
    path: URL_STATIC_PAGE,
    exact: true,
    component: LazyLoader(() => import("Components/Static/Static")),
    sidebar: {
      name: "Static Page",
      icon: <StrikethroughOutlined />,
    },
  },
];
